require("dotenv").config();
const jwt = require("jsonwebtoken");

const roleFromJwt = () => {
  // const token = getJwtCookie();
  const token = localStorage.getItem("token");
  if (token) {
    const decoded = jwt.decode(token);
    return decoded.role;
  } else {
    return false;
  }
};

const getJwtCookie = () => {
  const cookiestring = RegExp("jwt=[^;]+").exec(document.cookie);
  return decodeURIComponent(
    cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
  );
};

const isBasic = () => {
  return roleFromJwt() === "basic access";
};

const isFull = () => {
  return roleFromJwt() === "full access";
};

export default {
  roleFromJwt,
  isBasic,
  isFull,
  getJwtCookie,
};
